import React, { Component } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

class About extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div className="container">
          <h3>About Rwenzo</h3>

            <p className="topPageText">
              Rwenzo is a music streaming application for African music. Right now we cover mainly East Africa but will expand to other parts of Africa as well.
              We have music from all your favourite artists from Uganda, Kenya, Tanzania, Rwanda, Ethiopia... We hope to have The full app version release to everyone in January 2020. Rwenzo is currently available on Android phones only. We are working to have Rwenzo available
              on all platforms and devices. Thank you for your support!!
           </p>

          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default About;
