import React, { Component } from 'react';
import logo from '../rwenzoIcon.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class TermsOfUse extends Component {
  render() {
    return (
      <div>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
               <Link className="navbar-brand" to="/">  <img src={logo} alt="Icon" style={{width: '60px', height: '60px', borderRadius: '50%'}}  />   Rwenzo</Link>
        </nav>

        <div className="container">

  <div className="container" id="termsUseTop">


      <h1 >Rwenzo Terms and Conditions of Use</h1>

       <p className="pageWhiteText">Effective as of November 1, 2018<br/> </p>

       <p>1. <a href="#s1">Introduction</a><br />
     2. <a href="#s2">Changes to the Agreements</a><br />
     3. <a href="#s3">Enjoying Rwenzo</a><br />
     4. <a href="#s4">Rights we grant you</a><br />
     5. <a href="#s5">Third Party Applications</a><br />
     6. <a href="#s6">User-Generated Content</a><br />
     7. <a href="#s7">Rights you grant us</a><br />
     8. <a href="#s8">User guidelines</a><br />
     9. <a href="#s9">Infringement and reporting User Content</a><br />
     10. <a href="#s10">Service limitations and modifications</a><br />
     11. <a href="#s11">Brand Accounts</a><br />
     12. <a href="#s12">Customer support</a><br />
     13. <a href="#s13">Export control</a><br />
     14. <a href="#s14">Term and termination</a><br />
     15. <a href="#s15">Warranty and disclaimer</a><br />
     16. <a href="#s16">Limitation and time for filing</a><br />
     17. <a href="#s17">Third party rights</a><br />
     18. <a href="#s18">Entire Agreement</a><br />
     19. <a href="#s19">Severability and waiver</a><br />
     20. <a href="#s20">Assignment</a><br />
     21. <a href="#s21">Indemnification</a><br />
     22. <a href="#s22">Contact us</a></p>

     <p class="pageWhiteText">
       Hello, and welcome to Rwenzo. Please read our Terms and Conditions of Use (“Terms”) and Privacy Policy carefully because they affect
       your legal rights, including an agreement to resolve any disputes that may arise between us by arbitration on an
       individual basis instead of by class actions or jury trials. We hope you’re sitting comfortably and listening to some great music.
     </p>
     <p class="pageWhiteText">Here we go…</p>

  </div>

  <div>
    <h2><a id="s1"></a>1. Introduction</h2>
    <p class="pageWhiteText">
      Thanks for choosing Rwenzo (“Rwenzo”, “we”, “us”, “our”). By signing up or otherwise using the Rwenzo service, websites, and software
      applications (together, the “Rwenzo Service” or “Service”) or accessing any content
      or material that is made available by Rwenzo through the Service (the “Content”) you are entering into a binding contract with Rwenzo.
    </p>
    <p class="pageWhiteText"> Use of the Rwenzo Service relies on several technical requirements.<br/></p>
    <h4>System requirements</h4>
    <p class="pageWhiteText">The following are the system requirements for using Rwenzo and accessing Rwenzo content through the Rwenzo app: </p>
    <table class="table table-bordered">
          <thead>
          <tr>
          <td bgcolor="#1db954"><strong>Device</strong></td>
          <td bgcolor="#1db954"><strong>Model</strong></td>
          <td bgcolor="#1db954"><strong>OS</strong></td>
          </tr>
          </thead>
          <tbody>
          <tr class="pageWhiteText">
          <td>Android</td>
          <td>Any device</td>
          <td>Android OS 4.1 or above. five hundred MB free space</td>
        </tr>

        </tbody>
    </table>

    <p className="pageWhiteText"><strong>Note</strong>:The Rwenzo app can vary across different devices and operating systems.
      We recommend running the latest firmware on your device for the best experience.</p>
      <p class="pageWhiteText">
        Your agreement with us includes these Terms and Conditions of Use (“Terms”) and our Privacy Policy.
        (The Terms, Privacy Policy, and any additional terms that you agree to, as discussed in the Entire Agreement section,
        are referred to together as the “Agreements”.) If you wish to review the terms of the Agreements, the effective version of the
        Agreements can be found on Rwenzo’s website. You acknowledge that you have read and understood the Agreements, accept these Agreements,
        and agree to be bound by them. If you don’t agree with (or cannot comply with) the Agreements, then you may not use the Rwenzo Service
        or consume any Content.
      </p>

      <p class="pageWhiteText">
        Please read the Agreements carefully. They cover important information about Rwenzo Services provided to you. The Agreements include
        information about future changes to the Agreements,
        export controls, limitations of liability, privacy information, and resolution of disputes by arbitration instead of in court.
      </p>

      <p class="pageWhiteText">
        Any information that you provided during sign-up can be corrected during the
        sign-up process by returning to the previous screens and correcting erroneous information.
      </p>
      <p class="pageWhiteText">
        In order to use the Rwenzo Service and access the Content, you need to (1) be 18 or older or be 13 or older and have your parent or
        guardian’s consent to the Agreements, (2) have the power to enter a binding contract with us and not be barred from doing so under any
        applicable laws. You also promise
        that any registration information that you submit to Rwenzo is true, accurate, and complete, and you always agree to keep it that way.
      </p>

  </div>

  <div>
    <h2><a id="s2"></a>2. Changes to the Agreements</h2>
    <p class="pageWhiteText">
      Occasionally we may, in our discretion, make changes to the Agreements. When we make material changes to the Agreements,
      we’ll provide you with prominent notice as appropriate under the circumstances, e.g., by displaying a prominent notice within
      the Service or by sending you an email. In some cases, we will notify you in advance, and your continued use of the Service after
      the changes have been made will constitute your acceptance of the changes. Please therefore make sure you read any such notice carefully.
      If you do not wish to continue using the Service under the new version of the Agreements, you may terminate the Agreements by contacting us
      through the Customer Service contact form.
    </p>
  </div>

  <div>
    <h2><a id="s3"></a>3. Enjoying Rwenzo</h2>
    <p class="pageWhiteText">Here’s some information about all the ways you can enjoy Rwenzo.</p>
    <p class="pageWhiteText">3.1 Our Services</p>
    <p class="pageWhiteText">Rwenzo provides streaming services offering a selection of music and other content.
      All Rwenzo services are currently provided to you free-of-charge. </p>
  </div>

  <div>
    <h2><a id="s4"></a>4. Rights we grant you</h2>
    <p class="pageWhiteText">
      The Rwenzo Service and the Content are the property of Rwenzo or Rwenzo's licensors. We grant you a limited, non-exclusive, revocable
      license to make use of the Rwenzo Service, and a limited, non-exclusive, revocable license to make personal, non-commercial, entertainment
      use of the Content (the “License”). This License shall remain in effect until and unless terminated by you or Rwenzo. You promise and
      agree that you are using the Content for your own personal, non-commercial, entertainment use and that you will not redistribute or
      transfer the Rwenzo Service or the Content.
    </p>
    <p class="pageWhiteText">
      The Rwenzo software applications and the Content are licensed, not sold, to you, and Rwenzo and its licensors retain ownership of
      all copies of the Rwenzo software applications and
      Content even after installation on your personal computers, mobile handsets, tablets, and/or other relevant devices (“Devices”).
    </p>
    <p class="pageWhiteText">
      All Rwenzo trademarks, service marks, trade names, logos, domain names, and any other features of the
      Rwenzo brand (“Rwenzo Brand Features”) are the sole property of Rwenzo or its licensors.
      The Agreements do not grant you any rights to use any Rwenzo Brand Features whether for commercial or non-commercial use.
    </p>
    <p class="pageWhiteText">
      You agree to abide by our User guidelines and not to use the Rwenzo Service, the Content, or any part thereof in any manner not
      expressly permitted by the Agreements. Except for the rights
      expressly granted to you in these Agreements, Rwenzo grants no right, title, or interest to you in the Rwenzo Service or Content.
    </p>
    <p class="pageWhiteText">
      Third party software (for example, open source software libraries) included in the Rwenzo Service are licensed to you either
      under the Agreements or under the relevant third-party software
      library’s license terms as published in the help or settings section of our desktop and mobile client and/or on our website.
    </p>
  </div>

  <div>
    <h2><a id="s5"></a>5. Third Party Applications</h2>
    <p class="pageWhiteText">
      The Rwenzo Service is integrated with third party applications, websites, and services (“Third Party Applications”) to make
      available content, products, and/or services to you. These Third-Party Applications may have their own terms and conditions
      of use and privacy policies and your use of these Third-Party Applications will be governed by and subject to such terms and conditions
       and privacy policies. You understand and agree that Rwenzo does not endorse and is not responsible or liable for the behavior, features
       , or content of any Third-Party Application or for any transaction you may enter with the provider of any such Third-Party Applications.
    </p>
  </div>

  <div>
    <h2><a id="s6"></a>6. User-Generated Content</h2>
    <p>
      Rwenzo users may post, upload, and/or contribute (“post”) content to the Service (which may include, for example, pictures,
       text, messages, information, playlist compilations, and/or other types of content) (“User Content”). For the avoidance of doubt,
       “User Content” includes any such content posted to the Rwenzo Support Community as well as any other part of the Rwenzo Service.
    </p>
    <p>
      You promise that, with respect to any User Content you post on Rwenzo, (1) you have the right to post such User Content,
      and (2) such User Content, or its use by Rwenzo as contemplated by the Agreements, does not violate the Agreements, applicable law,
      or the intellectual property (including without limitation copyright), publicity, personality, or other rights of others or imply any
      affiliation with or endorsement of you or your User Content by Rwenzo or any artist, band, label, entity or individual without express
       written consent from such individual or entity.
    </p>
    <p>
      Rwenzo may, but has no obligation to, monitor, review, or edit User Content. In all cases, Rwenzo reserves the right to
      remove or disable access to any User Content for any or no reason, including but not limited to, User Content that, in Rwenzo’s
      sole discretion, violates the Agreements. Rwenzo may take these actions without prior notification to you or any third party.
      Removal or disabling of access to User Content shall be at our sole discretion, and we do not promise to remove or disable access to
      any specific User Content.
    </p>
    <p>
      You are solely responsible for all User Content that you post. Rwenzo is not responsible for
      User Content nor does it endorse any opinion contained in any User Content.<strong>
        YOU AGREE THAT IF ANYONE BRINGS A CLAIM AGAINST RWENZO RELATED TO USER CONTENT THAT YOU POST, THEN, TO THE EXTENT PERMISSIBLE UNDER
        LOCAL LAW, YOU WILL INDEMNIFY AND HOLD RWENZO HARMLESS FROM AND
        AGAINST ALL DAMAGES, LOSSES, AND EXPENSES OF ANY KIND (INCLUDING REASONABLE ATTORNEY FEES AND COSTS) ARISING OUT OF SUCH CLAIM.
      </strong>
    </p>
  </div>

  <div>
    <h2><a id="s7"></a>7. Rights you grant us</h2>
    <p>
      In consideration for the rights granted to you under the Agreements, you grant us the right (1) to allow the Rwenzo Service to use the processor, bandwidth,
      and storage hardware on your Device in order to facilitate the operation of the Service, (2) to provide advertising and other
      information to you, and (3) to allow our business partners to do the same. In any part of the Rwenzo Service, the Content you view,
      including its selection and placement, may be influenced by commercial considerations, including agreements with third parties.
      Some Content licensed, provided to or otherwise made available by Rwenzo (e.g. podcasts) may contain advertising as part of the Content.
      In such cases, Rwenzo will make such Content available to you unmodified.
    </p>
    <p>
      If you provide feedback, ideas or suggestions to Rwenzo in connection with the Rwenzo Service or Content (“Feedback”),
      you acknowledge that the Feedback is not confidential, and you authorize Rwenzo to use that Feedback without restriction and
      without payment to you. Feedback is considered a type of User Content.
    </p>
    <p>
      You grant Rwenzo a non-exclusive, transferable, sub-licensable, royalty-free, perpetual (or, in jurisdictions where this is not
      permitted, for a term equal to the duration of the Agreements plus twenty (20) years), irrevocable, fully paid, worldwide license to use,
      reproduce, make available to the public (e.g. perform or display), publish, translate, modify, create derivative works from, and distribute any of
      your User Content in connection with the Service through any medium, whether alone or in combination with other content or materials, in any manner and by any means,
      method or technology, whether now known or hereafter created. Aside from the rights specifically granted herein, you retain ownership of all rights,
      including intellectual property rights, in the User Content. Where applicable and permitted under applicable law, you also agree to waive any “moral rights”
      (or the equivalent under applicable law) such as your right to be identified as the author of any User Content, including Feedback, and your right to object to derogatory
      treatment of such User Content.
    </p>
  </div>

  <div>
    <h2><a id="s8"></a>8. User guidelines</h2>
    <p>
      Rwenzo respects intellectual property rights and expects you to do the same. We’ve established a few ground rules for you to
      follow when using the Service, to make sure Rwenzo stays enjoyable for everyone. Please follow these rules and encourage other users to do the same.
    </p>
    <p>
      The following is not permitted for any reason whatsoever:
      <ul>
        <li>
          copying, redistributing, reproducing, “ripping”, recording, transferring, performing or displaying to the public, broadcasting,
          or making available to the public any part of the Rwenzo Service or the Content, or otherwise making any use of the Rwenzo Service or
          the Content which is not expressly permitted under the Agreements or applicable law or
          which otherwise infringes the intellectual property rights (such as copyright) in the Rwenzo Service or the Content or any part of it;
        </li>
        <li>using the Rwenzo Service to import or copy any local files you do not have the legal right to import or copy in this way;</li>
        <li>transferring copies of cached Content from an authorized Device to any other Device via any means;</li>
        <li>
          reverse-engineering, decompiling, disassembling, modifying,
          or creating derivative works based on the Rwenzo Service, Content or any part thereof unless permitted by applicable law;
        </li>
        <li>circumventing any technology used by Rwenzo, its licensors, or any third party to protect the Content or the Service;</li>
        <li>selling, renting, sublicensing or leasing of any part of the Rwenzo Service or the Content;</li>
        <li>circumventing any territorial restrictions applied by Rwenzo or its licensors;</li>
        <li>artificially increasing play count or otherwise manipulating the Services by using a script or other automated process;</li>
        <li>
          removing or altering any copyright, trademark, or other intellectual property notices contained on or provided through the
          Rwenzo Service (including for the purpose of disguising or changing any indications of the ownership or source of any Content);
        </li>
        <li>providing your password to any other person or using any other person’s username and password;</li>
        <li>“crawling” the Rwenzo Service or otherwise using any automated means
          (including bots, scrapers, and spiders) to collect information from Rwenzo; or</li>
        <li>
          selling a user account or playlist, or otherwise accepting any compensation,
          financial or otherwise, to influence the name of an account or playlist or the content included on an account or playlist.
        </li>
      </ul>
    </p>
    <p>
      Please respect Rwenzo, the owners of the Content, and other users of the Rwenzo Service.
      Don’t engage in any activity, post any User Content, or register and/or use a username, which is or includes material that:
      <ul>
        <li>is offensive, abusive, defamatory, pornographic, threatening, or obscene;</li>
        <li>
          is illegal, or intended to promote or commit an illegal act of any kind, including
          but not limited to violations of intellectual property rights, privacy rights, or proprietary rights of Rwenzo or a third party;
        </li>
        <li>
          includes your password or purposely includes any
          other user’s password or purposely includes personal data of third parties or is intended to solicit such personal data;
        </li>
        <li>
          includes malicious content such as malware, Trojan horses, or viruses, or otherwise interferes with any user’s access to the Service;
        </li>
        <li>is intended to or does harass or bully other users;</li>
        <li>
          impersonates or misrepresents
          your affiliation with another user, person, or entity, or is otherwise fraudulent, false, deceptive, or misleading;
        </li>
        <li>uses automated means to artificially promote content;</li>
        <li>
          involves the transmission of unsolicited mass mailings or other forms of spam (“spam”), junk mail, chain letters, or similar, including through the Rwenzo inbox;
        </li>
        <li>
          involves commercial or sales activities, such as advertising, promotions, contests, sweepstakes, or pyramid schemes, that are not expressly authorized by Rwenzo;
        </li>
        <li>
          links to, references, or otherwise promotes commercial products or services, except as expressly authorized by Rwenzo;
        </li>
        <li>
          interferes with or in any way disrupts the Rwenzo Service, tampers with, breaches, or attempts to probe, scan, or test
          for vulnerabilities in the Service or Rwenzo’s computer systems, network, usage rules, or any of Rwenzo’s security components,
          authentication measures or any other protection measures applicable to the Service, the Content or any part thereof; or
        </li>
        <li>conflicts with the Agreements, as determined by Rwenzo.</li>
      </ul>
    </p>
    <p>
      You acknowledge and agree that posting any such User Content may result in immediate
      termination or suspension of your Rwenzo account. You also agree that Rwenzo may also reclaim your username for any reason.
    </p>
    <p>
      Please be thoughtful about how you use the Rwenzo Service and what you share. The Rwenzo Service includes social and
      interactive features, including the ability to post User Content, share content, and make certain information about you public.
      Remember that shared or publicly available information may be used and re-shared by other users on Rwenzo or across the web,
      so please use Rwenzo carefully and be mindful of your account settings. Rwenzo has no responsibility for your choices to post material
      on the Service.
    </p>
    <p>
      Your password protects your user account, and you are solely responsible for keeping your password confidential and secure.
      You understand that you are responsible for all use of your username and password on the Service. If your username or password is lost
      or stolen, or if you believe there has been unauthorized access to your account by third parties, please notify us immediately and change your password as soon as possible.
    </p>
  </div>

  <div>
    <h2><a id="s9"></a>9. Infringement and reporting User Content</h2>
    <p>
      Rwenzo respects the rights of intellectual property owners. If you believe that any Content infringes your intellectual property
      rights or other rights, see Rwenzo’s copyright policy. If Rwenzo is notified by a copyright holder that any Content infringes a
      copyright, Rwenzo may in its absolute discretion take actions without prior notification to the provider of that Content.
      If the provider believes that the content is not infringing, the provider may submit a counter-notification to Rwenzo with a request
      to restore the removed content.
    </p>
  </div>

  <div>
    <h2><a id="s10"></a>10. Service limitations and modifications</h2>
    <p>
      Rwenzo will make reasonable efforts to keep the Rwenzo Service operational. However, certain technical difficulties or maintenance may,
       from time to time, result in temporary interruptions. To the extent permissible under applicable law, Rwenzo reserves the right,
       periodically and at any time, to modify or discontinue, temporarily or permanently, functions and features of the Rwenzo Service,
       with or without notice, all without liability to you, except where prohibited by law, for any interruption, modification,
       or discontinuation of the Rwenzo Service or any function or feature thereof. You understand, agree, and accept that Rwenzo has no
       obligation to maintain, support, upgrade, or update the Service, or to provide all or any specific content through the Service.
       This section will be enforced to the extent permissible by applicable law. Rwenzo and/or the owners of any Content may, from time to
       time, remove any such Content without notice to the extent permitted by applicable law.
    </p>
  </div>

  <div>
    <h2><a id="s11"></a>11. Brand Accounts</h2>
    <p>
      If you establish a Rwenzo account on behalf of a company, organization, entity, or brand (a “Brand”, and such account a “Brand Account”),
      the terms “you” and “your”, as used throughout the Agreements, apply to both you and the Brand, as applicable.
    </p>
    <p>
      If you open a Brand Account, you represent and warrant that you are
      authorized to grant all permissions and licenses provided in the Agreements and to bind the Brand to the Agreements.
    </p>
    <h5>11.1 Following</h5>
    <p>
      The Brand may only follow users who first follow the Brand; and the Brand may not take any action that implies an endorsement or
      relationship between the Brand and the followed user, unless the Brand has independently
      obtained the rights to imply such an endorsement. Upon Rwenzo’s request, in its sole discretion, a Brand must cease following a user.
    </p>
    <h5>11.2 Listening</h5>
    <p>The Brand may not stream media.</p>
    <h5>11.3 Messaging</h5>
    <p>The Brand may only send messages to users who first send messages to the Brand.</p>
    <h5>11.4 Brand Playlists and Sharing</h5>
    <p>
      The Brand may not create or share any Rwenzo playlists, whether within the Rwenzo Service or elsewhere, that imply an endorsement or
      relationship between the Brand and any artist or any other party, unless the Brand has
      independently obtained the rights to imply such an endorsement. Brands may wish to consult Rwenzo’s Brand Playlist Guidelines.
    </p>

    <h4>Brand Playlists</h4>
    <p>You may be a company or a brand that uses Rwenzo’s social features to engage with your fans. As Rwenzo’s <strong>Terms and Conditions</strong> explain, brands may not engage in
      activity within the service that implies they are endorsed by any artists (unless, of course, they are).</p>
      <p>
        While you’ll need to use your own discretion to avoid implying any endorsements,
        many of Rwenzo’s brand customers have found the following rules of thumb helpful:
          <ul>
            <li>The more tracks you have on your playlist, the better — put at least 20 tracks on your playlists</li>
            <li>Don’t have any single artist appear on your playlist more than once</li>
            <li>If you have a reason to believe a specific artist may have a problem with your brand, it’s probably smart to stay away from that artist</li>
            <li>
              Keep your playlists editorial in nature; don’t try to make it a commercial for your product. Just like other Rwenzo users do,
              show the world what kind of music your brand likes to listen to while partying, driving, or enjoying a cup of coffee
            </li>
            <li>
              Take advantage of relationships you already have — if you’ve just hired an artist to be your new spokesperson, a playlist might
              be a great opportunity to show the world how much your brand loves the artist
            </li>
          </ul>
      </p>
  </div>

  <div>
    <h2><a id="s12"></a>12. Customer support</h2>
    <p>
      We will use reasonable endeavours to respond to all Customer Support Queries within a reasonable time frame, but we
      make no guarantees or warranties of any kind that any Customer Support Queries
      will be responded to within any particular time frame and/or that we will be able to satisfactorily answer any such queries
    </p>
  </div>

  <div>
    <h2><a id="s13"></a>13. Export control</h2>
    <p>
      Rwenzo’s products may be subject to U.S. export and re-export control laws and regulations or
      similar laws applicable in other jurisdictions, including the Export Administration Regulations
      (“EAR”) maintained by the U.S. Department of Commerce, trade and economic sanctions maintained by the Treasury Department’s
      Office of Foreign Assets Control (“OFAC”), and the International Traffic in Arms Regulations (“ITAR”) maintained by the
      Department of State. You warrant that you are (1) not located in Cuba, Iran, North Korea, Sudan, or Syria; and (2) are not a
      denied party as specified in the regulations listed above.
    </p>
    <p>
      You agree to comply with all applicable export and reexport control laws and regulations, including the EAR, trade and economic sanctions maintained by OFAC, and the ITAR. Specifically,
      you agree that you shall not – directly or indirectly – sell, export, reexport, transfer, divert, or otherwise dispose of any products, software, or technology
      (including products derived from or based on such technology) received from Rwenzo under the Agreements to any destination, entity, or person prohibited by any applicable laws or
      regulations of the United States or any other jurisdiction without obtaining prior authorization from the competent government authorities as required by those laws and regulations.
      This export control clause shall survive termination or cancellation of the Agreements.
    </p>
  </div>

  <div>
    <h2><a id="s14"></a>14. Term and termination</h2>
    <p>
      The Agreements will continue to apply to you until terminated by either you or Rwenzo. However, you acknowledge and agree that
      the perpetual license granted by you in relation to User Content, including Feedback, is irrevocable and will therefore continue
      after expiry or termination of any of the Agreements for any reason. Rwenzo may terminate the Agreements or suspend your access
      to the Rwenzo Service at any time, including in the event of your actual or suspected unauthorised use of the Rwenzo Service and/or
      Content, or non-compliance with the Agreements. If you or Rwenzo terminate the Agreements, or if Rwenzo suspends your access to
      the Rwenzo Service, you agree that Rwenzo shall have no liability or responsibility to you, and Rwenzo will not refund any amounts
      that you have already paid, to the fullest extent permitted under applicable law. To learn how to terminate your Rwenzo account,
      please contact us through Customer Service. This section will be enforced to the extent permissible by applicable law.
      You may terminate the Agreements at any time.
    </p>
    <p>
      Sections 6, 7, 8, 10, 13, 16, 17, 18, 19, 20, 21, and 22 herein, as well as any other sections of the Agreements that,
      either explicitly or by their nature, must remain in effect even after termination of the Agreements, shall survive termination.
    </p>
  </div>

  <div>
    <h2><a id="s15"></a>15. Warranty and disclaimer</h2>
    <p>
      WE ENDEAVOUR TO PROVIDE THE BEST SERVICE WE CAN, BUT YOU UNDERSTAND AND AGREE THAT THE RWENZO SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE”,
      WITHOUT EXPRESS OR IMPLIED WARRANTY OR CONDITION OF ANY KIND. YOU USE THE RWENZO SERVICE AT YOUR OWN RISK. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
      RWENZO AND ALL OWNERS OF THE CONTENT MAKE NO REPRESENTATIONS AND DISCLAIM ANY WARRANTIES OR CONDITIONS OF SATISFACTORY QUALITY, MERCHANTABILITY,
      FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. NEITHER RWENZO NOR ANY OWNER OF CONTENT WARRANTS THAT THE RWENZO SERVICE IS FREE OF MALWARE OR OTHER HARMFUL COMPONENTS.
      IN ADDITION, RWENZO MAKES NO REPRESENTATION NOR DOES IT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY THIRD-PARTY APPLICATIONS (OR THE CONTENT THEREOF), USER CONTENT,
      OR ANY OTHER PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY ON OR THROUGH THE RWENZO SERVICE OR ANY HYPERLINKED WEBSITE, OR FEATURED IN ANY BANNER OR OTHER ADVERTISING.
      YOU UNDERSTAND AND AGREE THAT RWENZO IS NOT RESPONSIBLE OR LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF THIRD-PARTY APPLICATIONS OR PRODUCTS
      OR SERVICES ADVERTISED ON OR THROUGH THE RWENZO SERVICE. AS WITH ANY PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT,
      YOU SHOULD USE YOUR JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE. NO ADVICE OR INFORMATION WHETHER ORAL OR IN WRITING OBTAINED BY YOU
      FROM RWENZO SHALL CREATE ANY WARRANTY ON BEHALF OF RWENZO IN THIS REGARD. SOME ASPECTS OF THIS SECTION MAY NOT APPLY IN SOME JURISDICTIONS IF PROHIBITED BY APPLICABLE LAW.
    </p>
    <p>
      THIS DOES NOT AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.
    </p>
  </div>

  <div>
    <h2><a id="s16"></a>16. Limitation and time for filing</h2>
    <p>
      YOU AGREE THAT, TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY PROBLEMS OR DISSATISFACTION WITH THE
      RWENZO SERVICE IS TO UNINSTALL ANY RWENZO SOFTWARE AND TO STOP USING THE RWENZO SERVICE. WHILE RWENZO ACCEPTS NO RESPONSIBILITY FOR THIRD PARTY
      APPLICATIONS OR THE CONTENT THEREOF, AND WHILE YOUR RELATIONSHIP WITH SUCH THIRD PARTY APPLICATIONS MAY BE GOVERNED BY SEPARATE AGREEMENTS WITH SUCH THIRD PARTIES,
      TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOUR SOLE AND EXCLUSIVE REMEDY, AS WITH RESPECT TO RWENZO, FOR ANY PROBLEMS OR DISSATISFACTION WITH THIRD PARTY APPLICATIONS
      OR THE CONTENT THEREOF, IS TO UNINSTALL AND/OR STOP USING ANY SUCH THIRD PARTY APPLICATIONS.
    </p>
    <p>
      TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL RWENZO, ITS OFFICERS, SHAREHOLDERS, EMPLOYEES, AGENTS, DIRECTORS, SUBSIDIARIES, AFFILIATES, SUCCESSORS, ASSIGNS, SUPPLIERS,
      OR LICENSORS BE LIABLE FOR (1) ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE, EXEMPLARY, OR CONSEQUENTIAL DAMAGES; (2) ANY LOSS OF USE, DATA, BUSINESS,
      OR PROFITS (WHETHER DIRECT OR INDIRECT), IN ALL CASES ARISING OUT OF THE USE OR INABILITY TO USE THE RWENZO SERVICE, THIRD PARTY APPLICATIONS,
      OR THIRD PARTY APPLICATION CONTENT, REGARDLESS OF LEGAL THEORY, WITHOUT REGARD TO WHETHER RWENZO HAS BEEN WARNED OF THE POSSIBILITY OF THOSE DAMAGES,
      AND EVEN IF A REMEDY FAILS OF ITS ESSENTIAL PURPOSE; OR (3) AGGREGATE LIABILITY FOR ALL CLAIMS RELATING TO THE RWENZO SERVICE, THIRD PARTY APPLICATIONS,
      OR THIRD PARTY APPLICATION CONTENT MORE THAN THE AMOUNTS PAID BY YOU TO RWENZO DURING THE PRIOR TWELVE MONTHS IN QUESTION, TO THE EXTENT PERMISSIBLE BY APPLICABLE LAW.
    </p>
    <p>
      Nothing in the Agreements removes or limits Rwenzo’s liability for fraud, fraudulent misrepresentation, death or personal injury caused by its negligence, and, if required by applicable law,
      gross negligence. Some aspects of this section may not apply in some jurisdictions if prohibited by applicable law.
    </p>
    <p>
      TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT ANY CLAIM AGAINST RWENZO MUST BE COMMENCED (BY FILING A DEMAND FOR ARBITRATION UNDER SECTION (24.2.1)
      OR FILING AN INDIVIDUAL ACTION UNDER SECTION (24.2.2) WITHIN ONE (1) YEAR AFTER THE DATE THE PARTY ASSERTING THE CLAIM FIRST KNOWS OR REASONABLY SHOULD KNOW OF THE ACT, OMISSION,
      OR DEFAULT GIVING RISE TO THE CLAIM; AND THERE SHALL BE NO RIGHT TO ANY REMEDY FOR ANY CLAIM NOT ASSERTED WITHIN THAT TIME PERIOD
    </p>
  </div>

  <div>
    <h2><a id="s17"></a>17. Third party rights</h2>
    <p>
      You acknowledge and agree that the owners of the Content and certain distributors (such as app store providers) are intended
      beneficiaries of the Agreements and have the right to enforce the Agreements directly against you. Other than as set out
      in this section, the Agreements are not intended to grant rights to anyone except you and Rwenzo, and in no event shall the
      Agreements create any third-party beneficiary rights. Furthermore, the rights to terminate, rescind, or agree to any variation,
      waiver, or settlement of the Agreements are not subject to the consent of any other person.
    </p>
    <p>
      If you have downloaded the App from the Apple, Inc. (“Apple”) App Store or if you are using the App on an iOS device, you acknowledge
      that you have read, understood, and agree to the following notice regarding Apple. This Agreement is between you and Rwenzo only,
      not with Apple, and Apple is not responsible for the Service and the content thereof. Apple has no obligation whatsoever to furnish
      any maintenance and support services with respect to the Service. In the event of any failure of the Service to conform to any
      applicable warranty, then you may notify Apple and Apple will refund any applicable purchase price for the App to you; and,
      to the maximum extent permitted by applicable law, Apple has no other warranty obligation whatsoever with respect to the Service.
      Apple is not responsible for addressing any claims by you or any third party relating to the Service or your possession and/or use
      of the Service, including, but not limited to: (1) product liability claims; (2) any claim that the Service fails to conform to any
      applicable legal or regulatory requirement; and (3) claims arising under consumer protection or similar legislation. Apple is not
      responsible for the investigation, defense, settlement and discharge of any third-party claim that the Service and/or your possession
      and use of the App infringe that third party’s intellectual property rights. You agree to comply with any applicable third-party terms,
      when using the Service. Apple, and Apple’s subsidiaries, are third party beneficiaries of this Agreement, and upon your acceptance of
      this Agreement, Apple will have the right (and will be deemed to have accepted the right) to enforce this Agreement against you as a
      third-party beneficiary of this Agreement. You hereby represent and warrant that (1) you are not located in a country that is subject
      to a U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist supporting” country; and (2) you are
      not listed on any U.S. Government list of prohibited or restricted parties.
    </p>
  </div>

  <div>
    <h2><a id="s18"></a>18. Entire Agreement</h2>
    <p>
      Other than as stated in this section or as explicitly agreed upon in writing between you and Rwenzo, the Agreements constitute all
      the terms and conditions agreed upon between you and
      Rwenzo and supersede any prior agreements in relation to the subject matter of these Agreements, whether written or oral.
    </p>
    <p>
      Please note, however, that other aspects of your use of the Rwenzo Service may be governed by additional agreements. To the extent that
      there is any irreconcilable conflict between any additional terms and these Terms, the additional terms shall prevail.
    </p>
  </div>

  <div>
    <h2><a id="s19"></a>19. Severability and waiver</h2>
    <p>
      Unless as otherwise stated in the Agreements, should any provision of the Agreements be held invalid or unenforceable for any reason or to any extent,
      such invalidity or enforceability shall not in any manner affect or render invalid or unenforceable the remaining provisions of the Agreements,
      and the application of that provision shall be enforced to the extent permitted by law.
    </p>
    <p>
      Any failure by Rwenzo or any third-party beneficiary to enforce the Agreements or
      any provision thereof shall not waive Rwenzo’s or the applicable third-party beneficiary’s right to do so.
    </p>
  </div>

  <div>
    <h2><a id="s20"></a>20. Assignment</h2>
    <p>
      Rwenzo may assign the Agreements or any part of them, and Rwenzo may delegate any of its obligations under the Agreements.
      You may not assign the Agreements or any part of them, nor transfer or sub-license your rights under the Agreements, to any third party.
    </p>
  </div>

  <div>
    <h2><a id="s21"></a>21. Indemnification</h2>
    <p>
      To the fullest extent permitted by applicable law, you agree to indemnify and hold Rwenzo harmless from and against all damages, losses, and expenses of any kind
      (including reasonable attorney fees and costs) arising out of: (1) your breach of this Agreement; (2) any User Content; (3) any activity in which you engage on or
      through the Rwenzo Service; and (4) your violation of any law or the rights of a third party.
    </p>
  </div>

  <div>
    <h2><a id="s22"></a>22. Contact us</h2>
    <p>
      If you have any questions concerning the Rwenzo Service or the Agreements, please contact Rwenzo Customer Service by visiting the About Us section of our website.
Thank you for reading our Terms. We hope you enjoy Rwenzo!
   <br/><br/>
    </p>
  </div>




</div>


        <div style={{height: '50px'}}></div>
        <div className="footer" >
          <Footer />
        </div>

      </div>
    );
  }
}

export default TermsOfUse;
