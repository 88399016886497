import React, { Component } from 'react';
import Logo from '../rwenzoIcon.png';
import { Link } from 'react-router-dom';

class NavBar extends Component {
  render() {
    return (
      <div>

          <nav>
              <div class="nav-wrapper">
                <Link to="/" class="brand-logo right" style={{marginTop: '10px', marginRight: '35px'}}><img src={Logo} style={{width: '45px', height: '45px', borderRadius: '50%'}}/></Link>
                <ul id="nav-mobile" class="left hide-on-med-and-down">
                  <li><Link to="/">Home</Link></li>
                  <li><Link to="/about-rwenzo">About</Link></li>
                  <li><Link to="/screenshots">Screenshots</Link></li>
                  <li><Link to="/app-feedback">Feedback</Link></li>
                  <li><Link to="/contact-us">Contact</Link></li>
                </ul>
              </div>
          </nav>

      </div>
    );
  }
}

export default NavBar;

/*
<nav>
    <div class="nav-wrapper">
      <img src={Logo}  style={{marginLeft: '50px', marginTop: '15px'}} alt="logo"/>
      <ul id="nav-mobile" class="right hide-on-med-and-down">
        <li style={{marginRight: '50px'}}><Link to="/">teste</Link></li>
      </ul>
    </div>
  </nav>
*/
