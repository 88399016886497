import React, { Component } from 'react';
import logo from '../rwenzoIcon.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class PrivacyCenter extends Component {
  render() {
    return (
      <div>

       <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
              <Link className="navbar-brand" to="/">  <img src={logo} alt="Icon" style={{width: '60px', height: '60px', borderRadius: '50%'}}  />   Rwenzo</Link>
       </nav>

       <div className="jumbotron  jumbotron-fluid">

         <div className="container" id="privacyCenterTop">

             <h1 id="">Privacy Center</h1>

              <p>
               Rwenzo uses personal data to create the best listening experience. Learn more about your data rights.
              </p>


         </div>

      </div>

      <div className="container">

      <div className="container">

         <p>
           At Rwenzo, we want to give you the best possible experience. To do this we process some personal data about you to understand
           your listening habits and to develop the best
           service for you and all our customers. But, be assured, your privacy and the security of your personal data are very important to us.
          <br/><br/>
          The purpose of this Privacy Center is to give you more information about the rights and controls you have in relation to your personal data,
          and to highlight some important sections of our Privacy Policy to you.

         </p>


      </div>

      <div className="container">
        <h1>What personal data does Rwenzo collect about me?</h1>
        <p>
          It is very important to us that you understand what personal data we collect about you, how we collect it, and why it’s necessary.
          <br/>
          We collect your personal data in the following ways:
          <br/>
        </p>
        <ol>
          <li><b>Personal data collected when you sign up for the Rwenzo Service - </b>when you sign up to the Rwenzo Service, we collect certain personal data so you can use the Rwenzo Service.
            This is the Account Registration Data category described in section 5 of our Privacy Policy.</li>
          <li><b>Personal data collected through your use of the Rwenzo Service - </b>when you use the Rwenzo Service, we collect personal data about your use of the Rwenzo Service such as what songs you have played and what playlists you have created.
            This is the Rwenzo Service Usage Data category contained in section 5 of our Privacy Policy.</li>
          <li><b>Personal data collected that enables us to provide you with additional features/functionality - </b>from time to time, you may also provide us with additional personal data or give us permission to collect personal data e.g. to provide
            you with more features or functionality. These are the Voluntary Mobile Data, Payment Data, Contests, Surveys and Sweepstakes Data, and Marketing Data categories contained in section 5 of our Privacy Policy. You will always have the option to change your mind and withdraw your permission at any time.</li>
        </ol>
      </div>

      <div className="container">
        <h1>Why does Rwenzo collect and use this personal data?</h1>
        <p>
          We collect and use your personal data for the following reasons:
          <br/>
        </p>
        <ul>
          <li>to provide, personalize, and improve your experience with the Rwenzo Service and other services and products provided by Rwenzo.</li>
          <li>to understand how you access and use the Rwenzo Service to ensure technical functionality of the Service, develop new products
             and services, and analyze your use of the Rwenzo Service.</li>
          <li>to communicate with you for Rwenzo Service-related purposes.</li>
          <li>to process your payment to prevent or detect fraud, including fraudulent payments and fraudulent use of the Rwenzo Service.</li>
          <li>to communicate with you, either directly or through one of our partners, for:
            <ul>
                                      <li>marketing,</li>
                                      <li>research,</li>
                                      <li>participation in contests, surveys and sweepstakes,</li>
                                      <li>promotional purposes,</li>
                                  </ul>
              via emails, notifications, or other messages, consistent with any permissions you may have given us.
          </li>
          <li>to provide you with features, information, advertising, or other content which is based on your specific location; and
          for other legitimate business purposes as explained in the Privacy Policy.
         </li>
        </ul>
        <p>To learn more about why Rwenzo uses your personal data, please refer to the Privacy Policy.</p>
      </div>

      <div className="container">
        <h1>How does Rwenzo protect my personal data?</h1>
        <p>We are committed to protecting our users’ personal data. We implement appropriate technical and organizational measures to help protect the security of your personal data; however,
           please note that no system is ever completely secure. We have implemented various policies including pseudonymization, encryption, access,
          and retention policies to guard against unauthorized access and unnecessary retention of personal data in our systems.
          <br/><br/>
          Your password protects your user account, so we encourage you to use a unique and strong password, limit access to
          your computer and browser, and log out after having used the Rwenzo Service.
        </p>
      </div>

      <div className="container">
        <h1>How does Rwenzo share / transfer my personal data?</h1>
        <p>
          Rwenzo shares your personal data globally with other companies in the Rwenzo Group.
          <br/><br/>
          Rwenzo may also subcontract processing to, or share your personal data with, third parties located in countries other than your home country.
          Your personal data may therefore be subject to privacy laws that are different from those in your country of residence.
          <br/><br/>
          Personal data collected within the European Union (“EU”) and Switzerland may, for example, be transferred to and processed by third parties located in a country outside of the EU and Switzerland.
          In such instances Rwenzo shall ensure that the transfer of your personal data is carried out in accordance with applicable privacy laws and that appropriate contractual,
          technical, and organizational measures are in place such as the Standard Contractual Clauses approved by the EU Commission.

        </p>
      </div>

      <div className="container">

          <h1>Your Personal Data Rights</h1>
          <p>
            You may be aware that a new European Union law, called the General Data Protection Regulation or "GDPR" gives certain rights to individuals in relation to their personal data.
            Accordingly, we have implemented additional transparency and access controls in our Privacy Center and Privacy Settings to help users take advantage of those rights.
            As available and except as limited under applicable law, individuals have the rights described below.
            <br/><br/>
            If you have further questions, please feel free to contact us.
          </p>
           <div className="col-lg-6 userData">
             <h3>Access your personal data</h3>
             <p>The right to be informed of and request access to the personal data we process about you.</p>
           </div>

           <div className="col-lg-6 userData">
             <h3>Update your personal data</h3>
             <p>The right to request that we amend or update your personal data where it is inaccurate or incomplete.</p>
           </div>


           <div className="col-lg-6 userData">
             <h3>Restrict the use of your personal data</h3>
             <p>The right to request that we temporarily or permanently stop processing all or some of your personal data.</p>
           </div>

           <div className="col-lg-6 userData">
             <h3>Object to the use of your personal data</h3>
             <p>The right to object to us processing your personal data where we no longer have a legitimate or legal need to process it.</p>
           </div>

           <div className="col-lg-6 userData">
             <h3>Object to direct marketing</h3>
             <p>The right to object to your personal data being processed for direct marketing purposes.</p>
           </div>

           <div className="col-lg-6 userData">
             <h3>Object to automated decision making</h3>
             <p>The right to not be subject to a decision based solely on automated decision making, including profiling, where the
               decision would have a legal effect on you or produce a similarly significant effect.</p>
           </div>

           <div className="col-lg-6 userData">
             <h3>Port your personal data</h3>
             <p>The right to request a copy of your personal data in a machine-readable format and the right to transmit
               that personal data for use in another party’s service.</p>
           </div>
           <div className="col-lg-6 userData">
             <h3>Erase your personal data</h3>
             <p>The right to request that we delete your personal data.</p>
           </div>

      </div>

      <div className="container">
        <h1>Where can I go to control what personal data Rwenzo uses?</h1>
        <p>You can access the it through your Account Settings.</p>
      </div>

    <div className="container">
      <h1>Questions?</h1>
      <p>If you have questions about your privacy, our Privacy Policy, information we have about you, or
        your rights and how to exercise them, please contact us.
        <br/>
        Email:  support@rwenzo.com
        <br/><br/>
      </p>
    </div>

    </div>

    <div className="footer">
      <Footer />
    </div>

  </div>
    );
  }
}

export default PrivacyCenter;
