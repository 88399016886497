import React, { Component } from 'react';
import logo from '../rwenzoIcon.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class PrivacyPolicy extends Component {
  render() {
    return (
      <div>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
               <Link className="navbar-brand" to="/">  <img src={logo} alt="Icon" style={{width: '60px', height: '60px', borderRadius: '50%'}}  />   Rwenzo</Link>
        </nav>

        <div class="container">

    <div className="container" id="privacyTop">


      <h1>Rwenzo Privacy Policy</h1>

       <p class="pageWhiteText">
        Effective as of November 1, 2018
        <br/><br/>
       </p>

       <p>
        1.<a href="#s1">Introduction</a><br />
        2.<a href="#s2">About this Policy</a><br />
        3. <a href="#s3">Your rights and your preferences: Giving you choice and control</a><br />
        4. <a href="#s4">How do we collect your personal data?</a><br />
        5. <a href="#s5">What personal data do we collect from you?</a><br />
        6. <a href="#s6">What do we use your personal data for?</a><br />
        7. <a href="#s7">Sharing your personal data</a><br />
        8. <a href="#s8">Data retention and deletion</a><br />
        9. <a href="#s9">Transfer to other countries</a><br />
        10. <a href="#s10">Links</a><br />
        11. <a href="#s11">Keeping your data safe</a><br />
        12. <a href="#s12">Children</a><br />
        13. <a href="#s13">Changes to this Privacy Policy</a><br />
        14. <a href="#s14">How to contact us</a>
      </p>


    </div>

    <div>
      <h2><a id="s1"></a>1. Introduction</h2>
      <p class="pageWhiteText">Thanks for choosing Rwenzo!</p>
      <p class="pageWhiteText">At Rwenzo, we want to give you the best possible experience to ensure that you enjoy our service today, tomorrow, and in the future.
        To do this we need to understand your listening habits, so we can deliver an exceptional and personalized service specifically for you.
        That said, your privacy and the security of your personal data is, and will always be, enormously important to us. So, we want to transparently
        explain how and why we gather, store, share and use your personal data - as well as outline the controls and choices you have around when and how you choose to share your personal data.</p>
        <p class="pageWhiteText">That is our objective, and this Privacy Policy (“Policy”) will explain exactly what we mean in further detail below.</p>
    </div>

  <div>
    <h2><a id="s2"></a>2.  About this Policy</h2>
    <p class="pageWhiteText">This Policy sets out the essential details relating to your personal data relationship with Rwenzo. The Policy applies to all Rwenzo services and
      any associated services (referred to as the ‘Rwenzo Service’).The terms governing your use of the Rwenzo Service are defined in
      our Terms and Conditions of Use (the “Terms and Conditions of Use”).</p>
    <p class="pageWhiteText">From time to time, we may develop new or offer additional services. If the introduction of these new or additional services results in any
      change to the way we collect or process your personal data we will provide you with more information and additional terms or policies.
      Unless stated otherwise when we introduce these new or additional services, they will be subject to this Policy.</p>
      <p class="pageWhiteText">
        The aim of this Policy is to:
        <ol class="pageWhiteText">
          <li>Ensure that you understand what personal data we collect about you,
            the reasons why we collect and use it, and who we share it with;</li>
          <li>Explain the way we use the personal data that you share with us
            in order to give you a great experience when you are using the Rwenzo Service; and</li>
          <li>Explain your rights and choices in relation to the personal data we collect and process
            about you and how we will protect your privacy.</li>
        </ol>
        </p>
        <p class="pageWhiteText">We hope this helps you to understand our privacy commitments to you. For information on how to contact us if you ever have any questions or concerns,
        please see the <a href="#s14">‘How to Contact Us’ Section 14 </a>below. Alternatively,
        if you do not agree with the content of this Policy, then please remember it is your choice whether you want to use the Rwenzo Service.
      </p>

  </div>

  <div>
    <h2><a id="s3"></a>3. Your rights and your preferences: Giving you choice and control </h2>
    <p class="pageWhiteText">You may be aware that a new European Union law, called the General Data Protection Regulation or "GDPR" gives certain rights to individuals in relation to their personal data.
      Accordingly, we have implemented additional transparency and access controls in our Privacy Center and Privacy Settings to help users take advantage of those rights.
      As available and except as limited under applicable law, the rights afforded to individuals are:
      <ul class="pageWhiteText">
        <li>Right of Access - the right to be informed of and request access to the personal data we process about you;</li>
        <li>Right to Rectification - the right to request that we amend or update your personal data where it is inaccurate or incomplete;</li>
        <li>Right to Erasure - the right to request that we delete your personal data;</li>
        <li>Right to Restrict - the right to request that we temporarily or permanently stop processing all or some of your personal data;</li>
        <li>Right to Object -
          <ul>
            <li>the right, at any time, to object to us processing your personal data on grounds relating to your situation.</li>
            <li>the right to object to your personal data being processed for direct marketing purposes.</li>
          </ul>
        </li>
        <li>Right to Data Portability - the right to request a copy of your personal data in electronic format and the right to
          transmit that personal data for use in another party’s service; and</li>
          <li>Right not to be subject to Automated Decision-making - the right to not be subject to a decision based solely on automated decision making,
            including profiling, where the decision would have a legal effect on you or produce a similarly significant effect.</li>
      </ul>
    </p>
  </div>

  <div>
    <h2><a id="s4"></a>4.  How do we collect your personal data?</h2>
    <p class="pageWhiteText">
      We collect your personal data in the following ways:
      <ol class="pageWhiteText">
        <li>When you sign up for the Rwenzo Service - when you sign up to the Rwenzo Service, we collect certain personal data,
          so you can use the Rwenzo Service such as your email address, and country.</li>
        <li>Through your use of the Rwenzo Service - when you use the Rwenzo Service, we collect personal data about your use of the Rwenzo Service,
          such as what songs you have played and what playlists you have created.</li>
        <li>Personal data collected that enables us to provide you with additional features/functionality - from time to time, you also may
          provide us with additional personal data or give us your permission to collect additional personal data e.g. to provide you with more
          features or functionality. As described further below (see Voluntary Music Data), we will not collect photos, precise mobile device location,
          voice data, or contacts from your device without your prior consent. You always will have the option to change your mind and withdraw your consent at any time.</li>
        <li>From third parties - we will receive personal data about you and your activity from third parties, including advertisers and
          partners we work with in order to provide you with the Rwenzo Service (please see ‘Sharing your personal data’ Section 7 below).
          We will use this personal data either where you have provided your consent to the third party or to Rwenzo to that data sharing
          taking place or where Rwenzo has a legitimate interest to use the personal data in order to provide you with the Rwenzo Service.</li>
      </ol>
    </p>
      <p class="pageWhiteText">We use anonymised and aggregated information for purposes that include testing our IT systems, research, data analysis, creating marketing and promotion models,
      improving the Rwenzo Service, and developing new features and functionality within the Rwenzo Service.
    </p>
  </div>

  <div>
    <h2><a id="s5"></a>5.  What personal data do we collect from you?</h2>
    <p class="pageWhiteText">We have set out in the tables below the categories of personal data we collect and use about you:</p>

    <p class="pageWhiteText"><em>Personal data collected when you sign up for the Rwenzo Service</em></p>
      <table class="table table-bordered">
            <thead>
            <tr>
            <td bgcolor="#1db954">Categories of personal data</td>
            <td bgcolor="#1db954">Description of category</td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td><strong>Account Registration Data</strong></td>
            <td>This is the personal data that is provided by you or collected by us to enable you to sign up for
              and use the Rwenzo Service. This includes your email address, and country.
            <br/>
            <br/>
          Some of the personal data we will ask you to provide is required in order to create your account.
           You also have the option to provide us with some additional personal data in order to make your account more personalized.
            <br/>
            <br/>
              The exact personal data we will collect depends on the type of Rwenzo Service plan you sign up for and whether you use a
              Third-Party Service (as defined in the Terms and Conditions of Use) to sign up and use the Rwenzo Service. If you use a Third-Party Service to create an account, we will receive personal data
              via that Third-Party Service but only when you have consented to that Third Party Service sharing your personal data with us
            </td>
            </tr>

            <tr>
            <td><strong>Rwenzo Service Usage Data</strong></td>
            <td>This is the personal data that is collected about you when you are using the Rwenzo Service - this may include:
            <br/>
              <ul>
                <li>Information about your interactions with the Rwenzo Service which includes the date and time of any requests you make,
                  songs you have listened to, playlists you create, video content you’ve watched, and your interactions with other Rwenzo users.
                  This also may include details of your use of Third-Party Applications and advertising you receive.</li>
                <li>User Content (as defined in the Terms and Conditions of Use) you post to Rwenzo including messages you send and/or receive
                  via Rwenzo and your interactions with the Rwenzo Customer Service team.</li>
                <li>Technical Data which may include URL information, cookie data, your IP address, the types of devices you are using to access or connect to the Rwenzo Service,
                  unique device IDs, device attributes, network connection type (e.g. Wi-Fi, 3-G, LTE, Bluetooth) and provider, network and device performance,
                  browser type, language, information enabling digital rights management, operating system, and Rwenzo application version. </li>
                <li>Motion-generated or orientation-generated mobile sensor data (e.g. accelerometer or gyroscope) required for
                  the purposes of providing specific features of the Rwenzo Service to you.</li>
              </ul>
            </td>
            </tr>

            <tr>
            <td><strong>Voluntary Mobile Data</strong></td>
            <td>In addition to the mobile data we collect to provide you with the Rwenzo Service (outlined above), you also have the
              option to give us your consent to collect additional personal data from your
              mobile device to provide you with features/functionality that will enhance your Rwenzo Service experience.
            <br/>
            <br/>
           We will not access any of the personal data listed below without first obtaining your consent:
            <br/>
              <ul>
                <li>Your photos - If you give us permission to access your photos or camera, we will only access images that you specifically choose to share with us
                  and metadata related to those images, such as the type of file and the size of the image. We will never </li>
                <li>Your precise mobile device location - If you give us permission to access your precise location, this enables us to access your GPS or
                  Bluetooth to provide location-aware functionality in the Rwenzo Service. Please note that this does not include your IP address. We use your IP address to determine
                  non-precise location, for example, what country you are in to comply with our licensing agreements;</li>
                <li>Your voice data - If you give us permission, this enables us to access the voice commands captured via your device microphone to enable you to interact with the Rwenzo Service with your voice.
                  Please note you will always have the ability to turn off the microphone feature; and,</li>
                <li>Your contacts - If you give us permission to access your contacts,
                  this enables us to access individual contacts stored on your device to help you find friends who use Rwenzo.</li>
              </ul>

            </td>
            </tr>

            <tr>
              <td><strong>Contests, Surveys and Sweepstakes Data</strong></td>
              <td>This personal data is used to allow you to sign up and participate in these types of promotions.
                The exact personal data collected will vary depending on the promotion.</td>
            </tr>

            <tr>
              <td><strong>Marketing Data</strong></td>
              <td>This personal data is used to enable Rwenzo and our partners / service providers to send you marketing communications either:
                <ul>
                  <li>Via email;</li>
                    <li>Whilst using the Rwenzo Service; and/or</li>
                      <li>Direct from the third party.</li>
                </ul>
              </td>
            </tr>

          </tbody>

      </table>
  </div>

  <div>
    <h2><a id="s6"></a>6.  What do we use your personal data for?</h2>
    <p>When you use or interact with the Rwenzo Service, we use a variety of technologies to process the personal data we collect about you for various reasons.
      We have set out in the table below the reasons why we process your personal data,
      the associated legal bases we rely upon to legally permit us to process your personal data, and the categories of personal data
      (identified in Section 5 ‘What personal data do we collect from you?’) used for these purposes:
    </p>
    <table class="table table-bordered">
          <thead>
          <tr>
          <td bgcolor="#1db954">Description of why Rwenzo processes your personal data (‘processing purpose’)</td>
          <td bgcolor="#1db954">Legal Basis for the processing purpose</td>
            <td bgcolor="#1db954">Categories of personal data used by Rwenzo for the processing purpose</td>
          </tr>
          </thead>
          <tbody>
          <tr>
          <td>To provide, personalize, and improve your experience with the Rwenzo Service and other services and products provided by Rwenzo,
            for example by providing customized, personalized, or localized content, recommendations, features, and advertising on or
            outside of the Rwenzo Service (including for third party products and services).
          </td>
          <td>
            <ul>
              <li>Performance of a Contract</li>
              <li>Legitimate Interest</li>
            </ul>
          </td>
          <td>
            <ul>
              <li>Account Registration Data</li>
              <li>Service Usage Data</li>
            </ul>
          </td>

        </tr>

        <tr>
        <td>To understand how you access and use the Rwenzo Service to ensure technical functionality of the Rwenzo Service, develop new products and services,
          and analyze your use of the Rwenzo Service, including your interaction with applications, advertising, products, and services that are made available,
          linked to, or offered through the Rwenzo Service.
        </td>
        <td>
          <ul>
            <li>Performance of a Contract</li>
            <li>Legitimate Interest</li>
          </ul>
        </td>
        <td>
          <ul>
            <li>Account Registration Data</li>
            <li>Service Usage Data</li>
          </ul>
        </td>

      </tr>

      <tr>
      <td>To communicate with you for Rwenzo Service-related purposes.
      </td>
      <td>
        <ul>
          <li>Performance of a Contract</li>
          <li>Legitimate Interest</li>
        </ul>
      </td>
      <td>
        <ul>
          <li>Account Registration Data</li>
          <li>Service Usage Data</li>
        </ul>
      </td>
    </tr>

    <tr>
    <td>To communicate with you, either directly or through one of our partners, for:
      <ul>
        <li>marketing,</li>
        <li>research,</li>
        <li>participation in contests, surveys and sweepstakes,</li>
        <li>promotional purposes,</li>
      </ul>
      via emails, notifications, or other messages,
       consistent with any permissions you may have communicated to us (e.g., through your Account Settings page).
    </td>
    <td>
      <ul>
        <li>Consent</li>
        <li>Legitimate Interest</li>
      </ul>
    </td>
    <td>
      <ul>
        <li>Contests, Surveys and Sweepstakes Data</li>
        <li>Marketing Data</li>
      </ul>
    </td>
  </tr>

  <tr>
  <td>To provide you with features, information, advertising, or other content which is based on your specific location.
  </td>
  <td>
    <ul>
      <li>Consent</li>
    </ul>
  </td>
  <td>
    <ul>
      <li>Voluntary Mobile Data</li>
    </ul>
  </td>

</tr>

        </tbody>
      </table>
      <p>If you require further information about the balancing test that Rwenzo has undertaken to justify its reliance on the
        legitimate interest legal basis under the GDPR, please see Section 14 ‘How to contact us’ for further details on how to contact us.</p>
  </div>

  <div>
    <h2><a id="s7"></a>7.  Sharing your personal data</h2>
    <p>We have set out the categories of recipients of the personal data collected or generated through your use of the Rwenzo Service.</p>
    <p><em>Publicly available information</em></p>
    <p>The following personal data will always be publicly available on the Rwenzo Service: your name and/or username, profile picture,
      who you follow and who follows you on the Rwenzo Service, your recently played artists, and your public playlists.</p>
      <p><em>Personal data you may choose to share</em></p>
      <p>
        The following personal data will only be shared with the categories of recipients outlined in the table below if:
        <br/>
        <ul>
          <li>you choose to make use of a specific Rwenzo Service feature
            where sharing of personal data is required for the proper use of the Rwenzo Service feature; or</li>
          <li>you grant us your permission to share the personal data, e.g. by selecting the appropriate setting in the Rwenzo Service.</li>
        </ul>
      </p>

      <table class="table table-bordered">
            <thead>
            <tr>
            <td bgcolor="#1db954"><strong>Categories of Recipients</strong></td>
            <td bgcolor="#1db954"><strong>Reason for sharing</strong></td>
            </tr>
            </thead>
            <tbody>
            <tr>
            <td><em>
              Third Party Applications you connect to your Rwenzo Account
            </em>
            </td>
            <td>
              If you connect your Rwenzo account to a Third-Party Application, such as, for example, social media, audio,
              television, or automotive platforms, Rwenzo may share your Service Usage Data so you can connect to your Rwenzo account.
            </td>
          </tr>

          <tr>
          <td><em>
            Third Party Applications you use to log into Rwenzo
          </em>
          </td>
          <td>
            If you log into a Third-Party Application using your Rwenzo account credentials,
            then that Third-Party Application may have access to certain Service Usage Data such as your playlists, saved content and activity.
            <br/><br/>
            You will receive a notification before connecting to the Third-Party Application to let you
            know what personal data will be shared / accessible to that Third-Party Application.
          </td>
        </tr>

        <tr>
        <td><em>
          Your Rwenzo Followers
        </em>
        </td>
        <td>
          There also may be times when you want us to share certain Service Usage Data,
          specifically information about your use of Rwenzo, with other Rwenzo users known as ‘Your Rwenzo Followers’.
          <br/><br/>
        For example, when you make playlists, you might want those playlists
        to be visible to others on the Rwenzo Service, but you can also make your playlists private at any time.
        </td>
      </tr>

      <tr>
      <td><em>
        Artists and Record Labels
      </em>
      </td>
      <td>
      You can ask us to share personal data (like your email address) with artists or record labels, or
       other partners who may want to directly send you news or promotional offers, but you can also revoke that consent at any time.
      </td>
    </tr>

    <tr>
    <td><em>
      Service Providers and Others
    </em>
    </td>
    <td>
      Information we may share;<br/>
      We use technical service providers which may operate the technical infrastructure that we need to provide the Rwenzo Service,
      in particular providers which host, store, manage, and maintain the Rwenzo application, its content and the data we process.
  We use technical service providers to help us communicate with you, as described in Section 6 of this Policy.
  We use marketing and advertising partners to show you more tailored content, or to help us understand your use of the Rwenzo Service,
  to provide you with a better service. We also may share personal data with certain marketing and advertising partners to send you promotional communications about Rwenzo.

    </td>
  </tr>

  <tr>
  <td><em>
    Rwenzo Partners
  </em>
  </td>
  <td>
    Information we may share;<br/>
    Depending on how you sign up for the Rwenzo Service (e.g. through a third-party service or a mobile provider), we share
    your Rwenzo username or other Account Registration Data as necessary to enable your account.
We also may share your personal data in a pseudonymised format with our music industry partners to help them understand how the
content they license to us is performing and to enable you to listen to streaming content via the Rwenzo Service.
We also share your personal data in a pseudonymised format with marketing partners who help us with promotional efforts
 and with advertisers that allow us to offer a free service.

  </td>
</tr>

<tr>
<td><em>
  Academic Researchers
</em>
</td>
<td>
  Information we may share;<br/>
  We may share your personal data for activities
  such as statistical analysis and academic study but only in a pseudonymised format.
</td>
</tr>

<tr>
<td><em>
  Other Rwenzo Group Companies
</em>
</td>
<td>
  Information we may share;<br/>
  We may share your personal data with other Rwenzo Group companies
  to carry out our daily business operations and to enable us to maintain and provide the Rwenzo Service to you.
</td>
</tr>

<tr>
<td><em>
  Law Enforcement and Data Protection Authorities
</em>
</td>
<td>
  Information we may share;<br/>
  We may share your personal data when we in good faith believe it is necessary for us to do so in order to comply with
   a legal obligation under applicable law, or respond to valid legal process, such as a search warrant, a court order, or a subpoena.
   <br/><br/>
   We also may share your personal data where we in good faith believe that it is necessary for the purpose of our own, or a
   third party’s legitimate interest relating to national security, law enforcement, litigation, criminal investigation, protecting the safety of any person,
   or to prevent death or imminent bodily harm, provided that we deem
   that such interest is not overridden by your interests or fundamental rights and freedoms requiring the protection of your personal data.
</td>
</tr>

<tr>
<td><em>
  Purchasers of our business
</em>
</td>
<td>
  Information we may share;<br/>
  We will share your personal data in those cases where we sell or negotiate to sell our business to a buyer or prospective buyer. In this situation,
  Rwenzo will continue to ensure the confidentiality of your personal data
  and give you notice before your personal data is transferred to the buyer or becomes subject to a different Privacy Policy.
</td>
</tr>

          </tbody>
      </table>

      <p>Learn more about how to manage notifications, your publicly available information, and what you share with others
        in the ‘Your rights and your preferences: Giving you choice and control’ Section 3 of this Policy.</p>

  </div>

  <div>
    <h2><a id="s8"></a>8.  Data retention and deletion</h2>
    <p>
      We keep your personal data only if necessary, to provide you with the Rwenzo Service and for legitimate and essential business purposes,
      such as maintaining the performance of the Rwenzo Service, making data-driven business decisions about new features and offerings, complying with our legal obligations, and resolving disputes.
      We keep some of your personal data for as long as you are a user of the Rwenzo Service. For example, we keep your playlists, song library, and account information.
    </p>
    <p>
      If you request, we will delete or anonymise your personal data so that it no longer identifies you,
      unless, we are legally allowed or required to maintain certain personal data, including situations such as the following:
      <ul>
        <li>
          If there is an unresolved issue relating to your account, such as an outstanding credit on
          your account or an unresolved claim or dispute we will retain the necessary personal data until the issue is resolved;
        </li>
        <li>
          Where we are required to retain the personal data for our legal, tax, audit,
          and accounting obligations, we will retain the necessary personal data for the period required by applicable law; and/or,
        </li>
        <li>
          Where necessary for our legitimate business interests such as fraud prevention or to maintain the security of our users.
        </li>
      </ul>
    </p>
  </div>

  <div>
    <h2><a id="s9"></a>9.  Transfer to other countries</h2>
    <p>
      Rwenzo shares your personal data globally with other companies in the Rwenzo Group in order to carry out the activities specified in this Policy. Rwenzo may also subcontract processing to,
      or share your personal data with, third parties located in countries other than your home country.
      Your personal data, therefore, may be subject to privacy laws that are different from those in your country of residence.
    </p>
    <p>
      Personal data collected within the European Union and Switzerland may, for example, be transferred to and processed by third parties
      located in a country outside of the European Union and Switzerland. In such instances Rwenzo shall ensure that the transfer of your
      personal data is carried out in accordance with applicable privacy laws and, in particular, that appropriate contractual, technical,
      and organisational measures are in place such as the Standard Contractual Clauses approved by the EU Commission.
    </p>

    <p>For further details of the security measures we use to protect your personal data, please see the ‘Keeping your personal data safe’ Section 11 of this Policy.</p>
  </div>

  <div>
    <h2><a id="s10"></a>10.  Links</h2>
    <p>
      We may display advertisements from third parties and other content that links to third-party websites.
      We cannot control or be held responsible for third parties’ privacy practices and content. If you click on a third-party advertisement
      or link, please understand that you are leaving the Rwenzo Service and any personal data you provide will not be covered by this Policy.
      Please read their privacy policies to find out how they collect and process your personal data.
    </p>
  </div>

  <div>
    <h2><a id="s11"></a>11.  Keeping your personal data safe</h2>
    <p>
      We are committed to protecting our users’ personal data. We implement appropriate technical and organisational measures to help protect
       the security of your personal data; however, please note that no system is ever completely secure. We have implemented various policies including pseudonymisation, encryption, access,
      and retention policies to guard against unauthorized access and unnecessary retention of personal data in our systems.
    </p>
    <p>
      Your password protects your user account, so we encourage you to use a unique and strong password,
      limit access to your computer and browser, and log out after having used the Rwenzo Service.
    </p>
  </div>

  <div>
    <h2><a id="s12"></a>12.  Children</h2>
    <p>
      The Rwenzo Service is not directed to children under the age of 13 years. However,
      in some countries, stricter age limits may apply under local law. Please see our Terms and Conditions of Use for further details.
    </p>
    <p>
      We do not knowingly collect personal data from children under 13 years or under the applicable age limit (the “Age Limit”).
      If you are under the Age Limit, please do not use the Rwenzo Service, and do not provide any personal data to us.
    </p>
    <p>
      If you are a parent of a child under the Age Limit and become aware that your child has provided personal data to Rwenzo,
       please contact us, and you may request exercise of your applicable rights
      detailed in the ‘Your rights and your preferences: Giving you choice and control’ Section 3 of this Policy.
    </p>
    <p>
      If we learn that we have collected the personal data of a child under the age of 13 years, we will take
      reasonable steps to delete the personal data. This may require us to delete the Rwenzo account for that child.
    </p>
  </div>

  <div>
    <h2><a id="s13"></a>13.  Changes to this Privacy Policy</h2>
    <p>We may occasionally make changes to this Policy.</p>
    <p>
      When we make material changes to this Policy, we’ll provide you with prominent notice as appropriate under the circumstances, e.g.,
      by displaying a prominent notice within the Rwenzo Service or by sending you an email. We may notify you in advance.
    </p>
    <p>Please, therefore, make sure you read any such notice carefully.</p>
    <p>If you want to find out more about this Policy and how Rwenzo uses your personal data, please visit the Privacy Center on rwenzo.com to find out more.</p>
  </div>

  <div>
    <h2><a id="s14"></a>14.  How to contact us</h2>
    <p>
      Thank you for reading our Privacy Policy. If you have any questions about this Policy,
      please contact our Data Protection Officer by writing to us at the following email address:
       <ul><li>support@rwenzo.com</li></ul>
    </p>
    <p>We hope you enjoy Rwenzo!<br/>
      © RKM Inc.
      <br/><br/>
   </p>
  </div>

</div>

          <div className="footer">
            <Footer />
          </div>

      </div>
    );
  }
}

export default PrivacyPolicy;
