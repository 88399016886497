import React, { Component } from 'react';
import Footer from '../components/Footer';
import logo from '../rwenzoIcon.png';
import { Link } from 'react-router-dom';

class AboutUs extends Component {
  render() {
    return (
      <div>
            <nav>
              <div class="nav-wrapper">
                <Link to="/">
                  <img src={logo} class="brand-logo" alt="logo" style={{width: '60px', height: '60px', borderRadius: '50%'}}/>
                  Rwenzo
                </Link>

              </div>
            </nav>


          <div className="container">

            <h1 id="home">About Us</h1>

            <p>
              Rwenzo is a music streaming app for African music.
              With Rwenzo, we are striving to make a platform where listening to your favourite music, artists is as easy as ABC– on your devices.
              We are working hard to expand our music collection by adding various artists and tracks so that all your favourite artists and music is on Rwenzo.
              You can also browse through the music collections we have like charts, artists, new music, playlists and keep personalizing your music experience by adding to your music collection.
              <br/><br/>
              Explore African music with Rwenzo. Give us a try and listen for free.
            </p>

            <div>
              <h2>Customer Service and Support</h2>
              <p>
                Email us: support@rwenzo.com<br/><br/>
                WhatsApp Us: +1 682-227-9778
              </p>
            </div>

          </div>

          <div className="footer">
            <Footer />
          </div>

      </div>
    );
  }
}

export default AboutUs;

/*

*/
