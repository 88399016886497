import React, { Component } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

class Contact extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div className="container">

          <div style={{marginTop: '200px', marginLeft: '340px'}}>
            <h3>Contact Info</h3>

            <p className="lead">Email:  support@rwenzo.com</p>
            <p className="lead">WhatsApp Us:  +1 682-227-9778 <br/><br/></p>
            <p className="lead">CEO/Founder Email:   richardmel@outlook.com</p>
          </div>

          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default Contact;
