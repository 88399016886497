import React, { Component } from 'react';
import logo from '../rwenzoIcon.png';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class CopyrightPolicy extends Component {
  render() {
    return (
      <div>

        <nav className="navbar navbar-expand-lg navbar-dark bg-dark" >
               <Link className="navbar-brand" to="/">  <img src={logo} alt="Icon" style={{width: '60px', height: '60px', borderRadius: '50%'}}  />   Rwenzo</Link>
        </nav>

        <div className="container">

          <div className="container" id="">


              <h1 >Rwenzo Copyright Policy</h1>

               <p>Last updated: November 1, 2018 </p>
               <p>
                 Hello, and thank you for visiting Rwenzo’s copyright policy page. Rwenzo respects intellectual property rights and expects its users to
                 do the same. If you are a copyright holder, or its agent, and you believe that any of
                 the copyrighted material which is directly available via the Rwenzo Service infringes your copyrighted work, please let us know.
               </p>
               <p className="pageWhiteText">
                 Contact us here:
                  support@rwenzo.com
               </p>
               <p>
                 A notification of alleged copyright infringement must be addressed to Rwenzo's copyright email as listed above.
                 Please include as much detail as possible to allow us to identify the facts or circumstances, including, where possible:
                 <ol>
                   <li>A physical or electronic signature of the owner
                     (or person authorised to act on behalf of the owner) of the copyright that is allegedly infringed;</li>
                   <li>Specific identification of each copyrighted work claimed to have been infringed;</li>
                   <li>A description of where the material believed to be infringing is located on Rwenzo Service or the Rwenzo Websites
                     (please be as detailed as possible and provide a URL to help us locate the material you are reporting);</li>
                   <li>Contact information for the complaining party, such as a complete name, address, telephone number, and email address;</li>
                   <li>
                     A statement that the complaining party has a good faith belief that use of the work(s) in
                     the manner complained of is not authorised by the copyright owner, its agent, or the law; and
                   </li>
                   <li>A statement that the information in the notification is accurate, and that the complaining party is the
                     owner of the right that is allegedly infringed, or agent for the owner.</li>
                 </ol>
               </p>

               <p>We should also let you know that Rwenzo has a policy to terminate in appropriate
                 circumstances the accounts of subscribers who are repeat infringers.</p>
                 <p>Copyright © 2016-2018 RKM INC. All rights reserved.</p>
                 <div style={{height: '50px'}}></div>

          </div>

        </div>

        <div className="footer" >
          <Footer />
        </div>

      </div>
    );
  }
}

export default CopyrightPolicy;
