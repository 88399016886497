import React, { Component } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';
import PicOne from '../resources/rwenzoLogin.png';
import PicTwo from '../resources/rwenzoHome.png';
import PicThree from '../resources/rwenzoArtists.png';
import PicFour from '../resources/rwenzoPlayer.png';

class Screenshots extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div className="container">
          <h3>App Screenshots</h3>

            <div className="container" id="screenshotCarousel">

              <img className="d-block w-100" src={PicOne} alt="First slide"/>
              <img class="d-block w-100" src={PicTwo} alt="Second slide"/>
              <img class="d-block w-100" src={PicThree} alt="Third slide"/>
              <img class="d-block w-100" src={PicFour} alt="Fourth slide"/>


        		</div>

          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default Screenshots;
