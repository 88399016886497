import React, { Component } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

class Feedback extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div className="container myDivStyle" classID="appFeedback">
          <h3>Send Us Feedback</h3>

          <p className="lead">
            Send feedback and let us know how we can improve our application. Your feedback is very important to us.
          </p>

          <form >

            <div class="form-group">
              <label className="label" for="email">Email address</label>
              <input type="email" class="form-control" id="email" name="email" aria-describedby="emailHelp" placeholder="Enter email"/>
              <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
            </div>

            <div class="form-group" style={{marginTop: '20px'}}>
              <label for="message" className="label">What would you like us to improve on? Is there a feature you like us put in app? <br/>Any feedback, questions, queries, concerns is welcome.</label>
              <textarea class="form-control" id="message" name="message" rows="3" style={{height: '300px'}}></textarea>
            </div>

              <button type="submit" class="btn btn-primary" id="myButton" style={{marginTop: '40px', marginLeft: '450px'}}>Submit</button>

          </form>

          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default Feedback;
