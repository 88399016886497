import React, { Component } from 'react';
import logo from './rwenzoIcon.png';
import './App.css';
import Footer from './components/Footer';
import NavBar from './components/NavBar';

class App extends Component {
  render() {
    return (
      <div>

        <NavBar />

        <div className="App container">

          <img src={logo} alt="logo" style={{marginTop: '35px'}}/>

          <p>
            Rwenzo Music Streaming Application. <br />
           Coming Soon......before end of 2019!
           <br /> <br />
           African Music Rocks!!! 
          </p>

          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>


      </div>
    );
  }
}

export default App;

/*
<header className="App-header">
  <img src={logo}  alt="logo" />
  <p>

  </p>
  <a
    className="App-link"
    href="https://reactjs.org"
    target="_blank"
    rel="noopener noreferrer"
  >
    Rwenzo Web
  </a>
</header>

*/
