import React, { Component } from 'react';
import Footer from './Footer';
import NavBar from './NavBar';

class JoinTeam extends Component {
  render() {
    return (
      <div>
        <NavBar />

        <div className="container">
          <h3>Join our Team! We are happy to have u!!!</h3>

          <p>
            We are working to put all the required information here, please check back later or send us<br/>
          an email at support@rwenzo.com
          </p>


          <div style={{height: '100px'}}></div>
        </div>

        <div className="footer">
          <Footer />
        </div>

      </div>
    );
  }
}

export default JoinTeam;
