import 'materialize-css/dist/css/materialize.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from "react-router-dom";
import './index.css';
import App from './App';
import AboutUs from './footerComponents/AboutUs';
import CopyrightPolicy from './footerComponents/CopyrightPolicy';
import PrivacyPolicy from './footerComponents/PrivacyPolicy';
import PrivacyCenter from './footerComponents/PrivacyCenter';
import TermsOfUse from './footerComponents/TermsOfUse';
import NavAbout from './components/About';
import Screenshots from './components/Screenshots';
import Feedback from './components/Feedback';
import Contact from './components/Contact';
import JoinUs from './components/JoinTeam';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter >

    <div>

      <Route path="/" exact component={App}></Route>
      <Route path="/home" exact component={App}></Route>
      <Route path="/about-us" exact component={AboutUs}></Route>
      <Route path="/copyright-policy" exact component={CopyrightPolicy}></Route>
      <Route path="/privacy-policy" exact component={PrivacyPolicy}></Route>
      <Route path="/privacy-center" exact component={PrivacyCenter}></Route>
      <Route path="/terms-use" exact component={TermsOfUse}></Route>
      <Route path="/about-rwenzo" exact component={NavAbout}></Route>
      <Route path="/screenshots" exact component={Screenshots}></Route>
      <Route path="/app-feedback" exact component={Feedback}></Route>
      <Route path="/contact-us" exact component={Contact}></Route>
      <Route path="/join-team" exact component={JoinUs}></Route>

    </div>
  </BrowserRouter>

  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
